import Modal from "./UI/Modal";
import "./Portfolio.css";
import PortfolioTypeOne from "./components/PortfolioTypeOne";
import portfolio_data from "./assets/data/protfolio-data";
import CloseIcon from "@mui/icons-material/Close";

function Portfolio(props) {
  return (
    <Modal>
      <div className='portfolio'>
        <div className='portfolio__close-icon'>
          <CloseIcon
            onClick={props.closePortfolio}
            style={{ fontSize: "2.2em" }}
          />
        </div>
        <h2>My Portfolio </h2>
        <hr />
        <div style={{fontSize: "12px", fontWeight: "700", color: "white", textAlign:"center", width:"100%", margin: "auto"}}>  (last updated - Jan, 2022)</div>
        {portfolio_data.map((data) => {
          return (
            <PortfolioTypeOne
              key={data.id}
              id={data.id}
              title={data.title}
              description={data.description}
              image={data.image}
              projectlink={data.link}
            />
          );
        })}
      </div>
    </Modal>
  );
}

export default Portfolio;
