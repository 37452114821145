import React from "react";
import "./AboutMe.css";
import Modal from "./UI/Modal";
import myPicture from "./assets/images/person.png";
import CloseIcon from "@mui/icons-material/Close";

function AboutMe(props) {
  return (
    <Modal>
      <div className='about-me'>
        <div className='about-me__close-icon'>
          <CloseIcon
            onClick={props.closeAboutme}
            style={{ fontSize: "2.2em" }}
          />
        </div>
        <div className='about-me__img'>
          <img src={myPicture} alt='' />
          <h3>
            Hey there! I am <br />
            Punith Sharon.
          </h3>
        </div>
        <hr />
        <div className='about-me__description'>
          <h3>About Me</h3>
          <p>
            "I am a Software Engineer based in Bangalore, India currently working at Lrnr Global Infotech Pvt. Ltd. a subsidiary of Lrnr (Pochys Ventures Inc.), a Delaware Corporation. Despite my background in Accounting, my journey into programming was sparked by a growing fascination with technology. I love diving into projects from scratch, crafting them into fully functional applications. Each line of code I write is infused with my passion and commitment to delivering high-quality work."
            {/* I am a full time Front-end Web Developer and a part time Graphic
            Designer based in Bangalore, India. I like to code things from
            scratch, and enjoy bringing ideas to life in the browser. I have an
            Accounting Backgroud and decided to move into programming stream
            because of my growing passion and love for it . Each line that I
            code is filled with tremendous amount of passion , love and hardwork
            which makes it special! 😄 */}
          </p>
        </div>
      </div>
    </Modal>
  );
}

export default AboutMe;
