import "./Button.css";

function Button(props) {
  return (
    <div class='btn-container'>
      <button
        onMouseEnter={props.mouseEnterLeave}
        onMouseLeave={props.mouseEnterLeave}
        className='btn btn-animate'
        onClick={props.handleClick}>
        <span>{props.label}</span>
      </button>
    </div>
  );
}

export default Button;
