const portfolio_data = [
  {
    title: "React - Food Ordering App",
    id: 15,
    description:
      " This is a food ordering app created using Rect.js. Front-end Techonologies: Html , Css , Javascript, React js.",
    image: "https://i.ibb.co/NWn70Nc/foodapp.jpg",
    link: "https://food-app-24c9d.web.app/",
  },
  {
    title: "React - Amazon Clone App - (UPDATED) - (only Desktop view)",
    id: 14,
    description:
      "This is a full scale e-commerce desktop based Amazon clone app with multiple functionalities and modern amazon design. Front-end Techonologies: Html , Css , Javascript , React JS, Swipper JS. Back-end Techonologies: Node js , Express js , Firebase authentication and hosting , Stripe (for payment processing) , and Postal pin code API.",
    image: "https://i.ibb.co/wYF2vMy/amazon-clone.jpg",
    link: "https://clone-b1ff0.web.app/",
  },
  {
    title: "Sharavath Group",
    id: 13,
    description:
      "The website was developed by me for Sharavath Group of Indian Enterprise during my course of employment with them.The website uses BootStrap and different JS libs for animation and to provide smooth experience.Front-end Techonologies: Html , Css , BootStrap , Javascript , Jquery.",
    image: "https://i.ibb.co/Vm1Dkhn/sharavath.jpg",
    link: "https://sharavathgroup.com",
  },
  {
    title: "My Portfolio - Previous Version",
    id: 12,
    description:
      "This website presents an earlier/first iteration of my portfolio, crafted with HTML, CSS, and JavaScript.",
    image: "https://i.ibb.co/TKVhtBj/portfolio-old.png",
    link: "https://punithsharon92.github.io/portfolio/",
  },
  {
    title: "React - Movies App",
    id: 11,
    description:
      "This is an app built using React and Redux where you can upload and display movie title and it's information. The movie details entered will be pused to database and can be retrieved by fetching the same.Front-end Techonologies: Html , Css , Javascript , React JS, React Redux and Redux Toolkit.Back-end Techonologies: Redux and Firebase",
    image: "https://i.ibb.co/wKbnrr3/movies.jpg",
    link: "https://starwars-dfec9.web.app/",
  },
  {
    title: "React - Amazon Clone App (only Desktop view)",
    id: 10,
    description:
      "This is a full scale e-commerce desktop based clone app with 'Add To Cart' , 'Log In' and 'Payment' functionalities.Front-end Techonologies: Html , Css , Javascript , React JS.Back-end Techonologies: Node js , Express js , Firebase , Stripe (for payment processing).",
    image: "https://i.ibb.co/wYF2vMy/amazon-clone.jpg",
    link: "https://clone-fbcb7.web.app/",
  },
  {
    title: "React - Keeper App",
    id: 9,
    description:
      "The Keeper App holds and keeps the notes that you add to it. You can add or remove as many notes as you desire.Front-end Techonologies: Html , Css , Javascript , React JS.",
    image: "https://i.ibb.co/QMHq3m3/keeper.jpg",
    link: "https://keeper-app-7a615.web.app/",
  },
  {
    title: "React - To-Do List App",
    id: 8,
    description:
      "You can add or remove ur To-Do list items using this app. Each time u insert a list item , the app will hold the item in an array and display the same on the screen. To remove the item from the item, click on the item.Front-end Techonologies: Html , Css , Javascript , React JS",
    image: "https://i.ibb.co/VJCcmsz/todolist.jpg",
    link: "https://to-do-list-app-7fa8e.web.app/",
  },
  {
    title: "React - Emoji App",
    id: 7,
    description:
      "The emoji app contains a collection of emojis and their meaning. Any number of emojis and their description can be added to the website by the Developer by just adding them to an array of emoji already existing in the code. Front-end Techonologies: Html , Css , Javascript , React JS.",
    image: "https://i.ibb.co/8mRwZL6/emoji.jpg",
    link: "https://react-emoji-app-9c447.web.app/",
  },
  {
    title: "Falcon website",
    id: 6,
    description:
      "This is a fully responsive website design (student project) created for one of the teams in MAAC institutions(bangalore) known as 'The Falcon'. The website uses javascript for smooth animations. Front-end Techonologies: Html , Css , Javascript , JQuery.",
    image: "https://i.ibb.co/SJxxrs6/falcon.jpg",
    link: "https://punithsharon92.github.io/Falcon/",
  },
  {
    title: "Design Periodt",
    id: 5,
    description:
      "This is an perfect example of an fully responsive app for graphic designers providing designing services.It displays the list of services provided (on hover) under a specific catagory mentioned on each box. It also has a work page to display one's work done in the past. Front-end Techonologies: Html , Css , Javascript , PHP(usually for header and footer if uploaded on a php Compatible hosting site).",
    image: "https://i.ibb.co/02RvXM6/design-periodt.jpg",
    link: "https://punithsharon92.github.io/designperiodt/",
  },
  {
    title: "Clipboard Landing Page",
    id: 4,
    description:
      "A clean simple fully responsive website design (student project) to showcase one's product on different digital platform. Front-end Techonologies: Html , Css , Javascript .",
    image: "https://i.ibb.co/DbMSsgZ/clipboard.jpg",
    link: "https://punithsharon92.github.io/ClipBoard/",
  },
  {
    title: "CanDog website",
    id: 3,
    description:
      "The Candog website is similar to tinder but it is for dogs. This is a fully responsive website design (student project) to demonstrate my skills with bootstrap. Front-end Techonologies: Html , Css , Javascript , JQuery , BootStrap.",
    image: "https://i.ibb.co/y4xKPjz/candog.jpg",
    link: "https://punithsharon92.github.io/CanDog/",
  },
  {
    title: "Drum Kit Roll",
    id: 2,
    description:
      "The Drum Kit website lets you click on a drum kit instrument shown on the screen and a corresponding sound of the drum kit intrument will be produced. Front-end Techonologies: Html , Css , Javascript.",
    image: "https://i.ibb.co/TctGbXq/drumkit.jpg",
    link: "https://punithsharon92.github.io/DrumKit/",
  },
  {
    title: "Simon Game Online (only Desktop view)",
    id: 1,
    description:
      "The Simon game is the exciting short term memory game in which players must repeat random sequences of colours by pressing the colored pads in the correct order. Front-end Techonologies: Html , Css , Javascript.",
    image: "https://i.ibb.co/th96RgP/simon.jpg",
    link: "https://punithsharon92.github.io/simon/",
  },
];

export default portfolio_data;
