import "./ModalTwo.css";

function ModalTwo(props) {
  return (
    <div className='modal-two' style={props.stylePosition}>
      <div className='modal-two__content'>{props.children}</div>
    </div>
  );
}

export default ModalTwo;
