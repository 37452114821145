import "./Introduction.css";

function Introduction() {
  return (
    <div className='introduction'>
      <p className='introduction__name introduction__name--fname'>Punith </p>
      <p className='introduction__name introduction__name--lname'>Sharon</p>
    </div>
  );
}

export default Introduction;
