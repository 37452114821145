import React, { useState } from "react";
import "./Home.css";
import AboutMe from "./AboutMe";
import ButtonContent from "./components/ButtonContent";
import Introduction from "./components/Introduction";
import Portfolio from "./Portfolio";
import HireMe from "./HireMe";
import AlphaCoder from "./AlphaCoderHeader";

function Home() {
  const [showAboutMe, setShowAboutMe] = useState(false);
  const [showHireMe, setShowHireMe] = useState(false);
  const [showPortfolio, setShowPorfolio] = useState(false);

  const handleClickShowAboutMe = () => {
    setShowAboutMe((prevValue) => {
      return !prevValue;
    });
  };

  const handleClickShowPortfolio = () => {
    setShowPorfolio((prevValue) => {
      return !prevValue;
    });
  };

  const handleClickShowHireMe = () => {
    setShowHireMe((prevValue) => {
      return !prevValue;
    });
  };

  return (
    <div className='home'>
      <AlphaCoder />
      <Introduction />
      <ButtonContent
        handleClickShowAboutMe={handleClickShowAboutMe}
        handleClickShowHireMe={handleClickShowHireMe}
        handleClickShowPortfolio={handleClickShowPortfolio}
      />
      <div style={{fontSize: "12px", fontWeight: "700", color: "white", position: "absolute", bottom:"30px", right:"30px"}}>last updated - Jan, 2022</div>
      {showAboutMe && <AboutMe closeAboutme={handleClickShowAboutMe} />}
      {showPortfolio && <Portfolio closePortfolio={handleClickShowPortfolio} />}
      {showHireMe && <HireMe closeHireme={handleClickShowHireMe} />}
    </div>
  );
}

export default Home;
