import { Button } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InstagramIcon from "@mui/icons-material/Instagram";
import CloseIcon from "@mui/icons-material/Close";
import "./HireMe.css";
import Modal from "./UI/Modal";
import { Email } from "@mui/icons-material";

function HireMe(props) {
  return (
    <Modal>
      <div className='hire-me'>
        <div className='hire-me__close-icon'>
          <CloseIcon
            onClick={props.closeHireme}
            style={{ fontSize: "2.2em" }}
          />
        </div>
        <div className='hire-me__write-to-me'>
          <h3>Write to me</h3>
          <p>
            I am available for full time, part time and freelance <br />
            work. Connect with me.
          </p>
          <form
            action='https://formcarry.com/s/DKsZt7f-1'
            method='POST'
            className='hire-me__contact-form'>
            <input
              type='text'
              name='FullName'
              class='hire-me__name'
              placeholder='Your Full Name'
              required
            />

            <input
              type='email'
              name='email'
              class='hire-me__email'
              placeholder='Your E-mail'
              required
            />
            <input
              type='text'
              name='subject'
              class='hire-me__subject'
              placeholder='Subject'
              required
            />
            <textarea
              name='message'
              class='hire-me__name'
              placeholder='Type in your message here'
              // rows={7}
              // cols={46}
              required
            />
            <input type='hidden' name='_gotcha' />

            {/* use this to prevent spam */}

            <Button type='submit' name='submit' class='hire-me__submit-btn'>
              Send
            </Button>
          </form>
        </div>
        <div className='hire-me__division'>
          <hr />
        </div>
        <div className='hire-me__social'>
          <span>
            <a
              style={{ textDecoration: "none" }}
              href='https://wa.me/+919611549660'>
              <WhatsAppIcon style={{ fontSize: "2.5em" }} />
            </a>
          </span>
          <span>
            <a href='mailto:punithsharon.92@gmail.com'>
              <Email style={{ fontSize: "2.5em" }} />
            </a>
          </span>
          <span>
            <a href='https://www.instagram.com/punithsharon/'>
              <InstagramIcon style={{ fontSize: "2.5em" }} />
            </a>
          </span>
        </div>
      </div>
    </Modal>
  );
}

export default HireMe;
