import React from "react";
import ModalTwo from "../UI/ModalTwo";
import "./SkillSet.css";
import CloseIcon from "@mui/icons-material/Close";

function SkillSet(props) {
  return (
    <ModalTwo stylePosition={{ right: "316px" }}>
      <CloseIcon
        onClick={props.closeSkillSet}
        style={{
          fontSize: "1.5em",
          float: "right",
          cursor: "pointer",
        }}
      />
      <div className='skill-set'>
        <h3>Skill Set</h3>
        <div className='skill-set__skills'>
          <p>HTML </p>
          <p className='skill-set__bar' style={{ width: "80%" }}></p>
        </div>
        <div className='skill-set__skills'>
          <p>Javascript</p>
          <p className='skill-set__bar' style={{ width: "70%" }}></p>
        </div>
        <div className='skill-set__skills'>
          <p>CSS</p>
          <p className='skill-set__bar' style={{ width: "90%" }}></p>
        </div>
        <div className='skill-set__skills'>
          <p>React JS ♥ ★ </p>
          <p className='skill-set__bar' style={{ width: "90%" }}></p>
        </div>
        
        <div className='skill-set__skills'>
          <p>TypeScript </p>
          <p className='skill-set__bar' style={{ width: "60%" }}></p>
        </div>

        <div className='skill-set__skills'>
          <p>J Query </p>
          <p className='skill-set__bar' style={{ width: "60%" }}></p>
        </div>

        <div className='skill-set__skills'>
          <p>BootStrap </p>
          <p className='skill-set__bar' style={{ width: "70%" }}></p>
        </div>

        <div className='skill-set__skills'>
          <p>Firebase </p>
          <p className='skill-set__bar' style={{ width: "70%" }}></p>
        </div>

        <div className='skill-set__skills'>
          <p>Redux Tool Kit </p>
          <p className='skill-set__bar' style={{ width: "80%" }}></p>
        </div>

        <div className='skill-set__skills'>
          <p>Adobe Illustrator </p>
          <p className='skill-set__bar' style={{ width: "80%" }}></p>
        </div>

        <div className='skill-set__skills'>
          <p>Adobe Photoshop </p>
          <p className='skill-set__bar' style={{ width: "60%" }}></p>
        </div>

        <div className='skill-set__skills'>
          <p style={{ padding: "20px 0" }}>And more... </p>
        </div>
      </div>
    </ModalTwo>
  );
}

export default SkillSet;
