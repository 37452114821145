import "./ButtonContent.css";
import Button from "../UI/Button";
import { useState } from "react";
import PsychologyAltIcon from "@mui/icons-material/PsychologyAlt";
import ContactEmergencyIcon from "@mui/icons-material/ContactEmergency";
import InventoryIcon from "@mui/icons-material/Inventory";

function ButtonContent(props) {
  const [mouseOverAboutMe, setMouseOverAboutMe] = useState(false);
  const [mouseOverHireMe, setMouseOverHireMe] = useState(false);
  const [mouseOverPortfolio, setMouseOverPorfolio] = useState(false);

  // Mouse Over Functionality

  const handleMouseOverAboutMe = () => {
    setMouseOverAboutMe((prevValue) => {
      return !prevValue;
    });
  };
  const handleMouseOverPortfolio = () => {
    setMouseOverPorfolio((prevValue) => {
      return !prevValue;
    });
  };
  const handleMouseOverHireMe = () => {
    setMouseOverHireMe((prevValue) => {
      return !prevValue;
    });
  };

  return (
    <div className='btn-content'>
      <Button
        handleClick={props.handleClickShowAboutMe}
        mouseEnterLeave={handleMouseOverAboutMe}
        label={
          !mouseOverAboutMe ? (
            "About Me"
          ) : (
            <PsychologyAltIcon style={{ fontSize: "2.5em" }} />
          )
        }
      />
      <Button
        handleClick={props.handleClickShowPortfolio}
        mouseEnterLeave={handleMouseOverPortfolio}
        label={
          !mouseOverPortfolio ? (
            "Portfolio"
          ) : (
            <InventoryIcon style={{ fontSize: "2em" }} />
          )
        }
      />
      <Button
        handleClick={props.handleClickShowHireMe}
        mouseEnterLeave={handleMouseOverHireMe}
        label={
          !mouseOverHireMe ? (
            "Contact Me"
          ) : (
            <ContactEmergencyIcon style={{ fontSize: "2em" }} />
          )
        }
      />
    </div>
  );
}

export default ButtonContent;
