import "./PortfolioTypeOne.css";

function PortofolioTypeOne(props) {
  const num = props.id % 2;
  const even = " portfolio-one__img odd";
  const odd = "portfolio-one__img even";

  return (
    <div className='portfolio-one'>
      <div className={num === 0 ? even : odd}>
        <a href={props.projectlink} target='_blank'>
          <img className='portfolio-one__img-cover' src={props.image} alt='' />
        </a>
      </div>
      <hr />
      <div className='portfolio-one__about'>
        <h3 className='portfolio-one__title'>{props.title}</h3>
        <div className='portfolio-one__despcription'>{props.description}</div>
      </div>
    </div>
  );
}

export default PortofolioTypeOne;
