import React, { useState } from "react";
import Logo from "./assets/images/logo.png";
import "./AlphaCoderHeader.css";
import SchoolIcon from "@mui/icons-material/School";
import SkillSet from "./components/SkillSet";
import PsychologyIcon from "@mui/icons-material/Psychology";
import Education from "./components/Education";

function AlphaCoderHeader() {
  const [showSkill, setShowSkill] = useState(false);
  const handleSkillClick = () => {
    setShowSkill((prevValue) => {
      return !prevValue;
    });
    setShowEdu(false);
  };
  const [showEdu, setShowEdu] = useState(false);
  const handleEduClick = () => {
    setShowEdu((prevValue) => {
      return !prevValue;
    });
    setShowSkill(false);
  };

  return (
    <div className='alphacoder-logo'>
      <a href='#home'>
        <img src={Logo} alt='logo-img' />
        <span>alpha coder</span>
      </a>
      <div className='alphacoder-details'>
        <a href='#home' onClick={handleSkillClick}>
          <PsychologyIcon style={{ fontSize: "2.4em" }} className='icon' />
        </a>
        {/* //show or hide modal condition */}
        {showSkill && <SkillSet closeSkillSet={handleSkillClick} />}

        {/* <a href='#home' onClick={handleEduClick}> */}
          {/* <SchoolIcon style={{ fontSize: "2.3em" }} className='icon' /> */}
          {/* <span className='tooltiptext'>Education</span> */}
        {/* </a> */}
        {/* {showEdu && <Education closeEdu={handleEduClick} />} */}

        <a
          href='https://www.behance.net/punithsharon/projects'
          target={"blank"}
          style={{
            fontSize: "1.95em",
            textDecoration: "none",
            position: "relative",
            bottom: "30px",
            left: "8px",
          }}>
          <p className='icon'>
            Bē
            {/* <span className='tooltiptext'>Behance Profile</span> */}
          </p>
        </a>
      </div>
    </div>
  );
}

export default AlphaCoderHeader;
